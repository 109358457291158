import React, { useState, useEffect } from 'react';
import { Grid, Card, CardMedia, CardContent, Typography, Button, Box, CircularProgress, Alert } from '@mui/material';
import axios from 'axios';

const CarList = () => {
  const [cars, setCars] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCars = async () => {
      try {
        setLoading(true);
        const response = await axios.get('https://autolubin.com/api/cars/');
        setCars(response.data);
        setError(null);
      } catch (error) {
        setError('Error loading cars. Please try again later.');
        console.error('Error fetching cars:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCars();
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        {cars.map((car) => (
          <Grid item xs={12} sm={6} md={4} key={car.id}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardMedia
                component="img"
                height="200"
                image={car.image_url}
                alt={`${car.year} ${car.make} ${car.model}`}
                sx={{ objectFit: 'cover' }}
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="h6" gutterBottom>
                  {car.year} {car.make} {car.model}
                </Typography>
                <Typography color="text.secondary">
                  {car.location}
                </Typography>
                <Typography variant="h6" color="primary">
                  ${car.price.toLocaleString()}
                </Typography>
                <Typography>
                  {car.mileage.toLocaleString()} miles • {car.transmission}
                </Typography>
                <Button 
                  variant="contained" 
                  fullWidth 
                  href={car.listing_url}
                  target="_blank"
                  sx={{ mt: 2 }}
                >
                  View Details
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CarList;
