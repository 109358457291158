import React, { useState } from 'react';
import { Box, TextField, Button, Autocomplete } from '@mui/material';

const SearchBar = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const makes = ['Ferrari', 'Porsche', 'Mercedes-Benz', 'BMW', 'Lamborghini'];

  const handleSearch = () => {
    onSearch(searchTerm);
  };

  return (
    <Box sx={{ p: 2, display: 'flex', gap: 2 }}>
      <Autocomplete
        freeSolo
        options={makes}
        sx={{ width: 300 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search cars"
            variant="outlined"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        )}
      />
      <Button variant="contained" onClick={handleSearch}>
        Search
      </Button>
    </Box>
  );
};

export default SearchBar;
