import React, { useState } from 'react';
import { Container, CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import CarList from './components/CarList';
import SearchBar from './components/SearchBar';
import axios from 'axios';

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#FFD700',
    },
  },
});

function App() {
  const [searchResults, setSearchResults] = useState(null);

  const handleSearch = async (searchTerm) => {
    try {
      const response = await axios.get(`https://autolubin.com/api/cars/search/?q=${searchTerm}`);
      setSearchResults(response.data);
    } catch (error) {
      console.error('Error searching cars:', error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <SearchBar onSearch={handleSearch} />
        <CarList searchResults={searchResults} />
      </Container>
    </ThemeProvider>
  );
}

export default App;
